<!-- add by Liwh time:2024-09-01 description:轮播图选择关联产品页面 -->

<template>
    <el-dialog title="选择公告" :model-value="modelValue" @update:model-value="updateVisible" class="roll-dialog">
        <el-table v-loading="loading" element-loading-text="加载中，请稍后..." :data="management" key="slot"
            @row-click="singleElection" ref="tableRef" style="text-align: center">
            <el-table-column label="" width="40vw">
                <template #default="scope">
                    <el-radio class="radio" v-model="templateSelection" :label="scope.row.id">&nbsp;</el-radio>
                </template>
            </el-table-column>
            <el-table-column prop="title" label="标题" width="80">
                <template #default="{ row }">
                    <el-tooltip class="item" :content="row.title" placement="top" effect="dark" :open-delay="0"
                        :hide-after="200">
                        <div class="ellipsis">
                            {{ row.title }}
                        </div>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column label="内容" width="300">
                <template #default="scope">
                    
                    <div v-if="scope.row.mediaType !== 2" v-html="scope.row.content">
                        
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="180" />
            <el-table-column prop="status" label="发布状态" width="180">
                <template #default="scope">
                    <el-tag type="danger" v-if="scope.row.status === 1">未发布</el-tag>
                    <el-tag type="success" v-if="scope.row.status === 2">已发布</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="mediaUrl" label="发布视频或图片" width="200">
                <template #default="scope">
                    <div style="width: 50px;height: 50px;display: flex;align-items: center;">
                        <el-image fit="fill" lazy v-if="scope.row.mediaType === 1"
                            :src="`https://www.jingtengyiliao.com/api/common/download?name=${scope.row.mediaUrl}`">
                        </el-image>
                        <video style="width: 200px ;height: 80px;" v-if="scope.row.mediaType === 2"
                            :src="`http://jt.jingtengyiliao.com/${scope.row.mediaUrl}`" controls></video>
                    </div>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="" label="详情" width="150vw" flexd="right">
                <template #default="scope">
                    <el-button type="primary" @click="detail(scope.row.id)">详情</el-button>
                </template>
            </el-table-column> -->
        </el-table>
        <!-- <el-pagination v-model:current-page="currentPage4" v-model:page-size="pageSize4"
                     @size-change="handleSizeChangese" @current-change="handleCurrentChangese"
                     :current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="10"
                     layout="total, sizes, prev, pager, next, jumper" :total="tatol">
      </el-pagination> -->
        <template #footer>
            <el-button @click="handleCancel">取消</el-button>
            <el-button type="primary" @click="handleConfirm">确定</el-button>
        </template>
    </el-dialog>

</template>

<script setup>
import { reactive, ref, toRaw, defineProps, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Picture as IconPicture } from '@element-plus/icons-vue'

const templateSelection = ref('');
const images = ref([])
const image = ref([])
const tatol = ref(0);
const management = ref([]);
const managem = ref(null);
const loading = ref(true)
const router = useRouter();
const input = ref('');
const manage = localStorage.getItem('permissionsStatus');
const value2 = ref(false)
const page = reactive({ pageNum: 1, pageSize: 10 });
const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true
    },
});
const emit = defineEmits(['confirm', 'update:modelValue']);



const selectedResult = ref('');


onMounted(() => {
    siftsea();
})

const handleConfirm = () => {
    if (!selectedResult.value) {
        ElMessage.error('请选择关联公告');
        return;
    }
    emit('confirm', selectedResult.value);
    emit('update:modelValue', false);
};

const handleCancel = () => {
    emit('update:modelValue', false);
};

const updateVisible = (value) => {
    emit('update:modelValue', value);
};

watch(() => props.modelValue, (newVal) => {
    if (!newVal) {
        selectedResult.value = ''; // 重置选择
    }
});

const singleElection = (row) => {
    selectedResult.value = row;
};



const handleSizeChangese = (val) => {
    page.pageSize = val;
    console.log(val);
    // if (value2.value === true) {
    //     offshelf()
    //     return
    // }
    loading.value = true;
    siftsea();
};
//当前页改变时触发
const handleCurrentChangese = (val) => {
    page.pageNum = val;
    console.log(val);
    // if (value2.value === true) {
    //     offshelf()
    //     return
    // }
    loading.value = true;
    siftsea();
};
//设置分类

const siftsea = () => {
    value2.value = false
    input.value = ''
    instance.get(`/api/announcement/getAnnouncementList`)
        .then(response => {
            managem.value = response.data.data;
            management.value = toRaw(managem.value);
            // tatol.value = response.data.data.totalCount
            console.log(management.value);

            loading.value = false;
            console.log(images.value);
            console.log(image.value);
        }).catch(error => {
            console.log(error);
            ElMessage({
                showClose: true,
                message: '请登录',
                type: 'error',
            })
            router.push('./')
        })
}
const detail = (id) => {
    console.log(id);
    router.push({
        path: '/productdetails',
        query: {
            msg: id
        }
    })
};



</script>
<style>
.el-dialog {
    width: 82%;
}

.roll-dialog .el-dialog__body {
    padding: 3px 30px;
    overflow-y: auto;
    height: calc(100vh - 140px);
}
 .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}
</style>
<!-- 添加公告 -->

<template>
  <el-container class="Wrapper">
    <el-container class="containersae">
      <el-scrollbar height="100vh" style="margin-left: -2vw;">
      <el-card style="">
        <h1 style="color: orange">发布内容公告</h1>
        <el-form label-width="100px">
          <el-form-item label="公告标题">
            <el-input v-model="c.title" placeholder="请输入公告标题" class="inputAddnn"></el-input>
          </el-form-item>
          <el-form-item label="公告状态">
            <el-radio-group v-model="c.status">
              <el-radio label="1">草稿箱</el-radio>
              <el-radio label="2">发布</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="选择类型" class="nas">
            <el-select placeholder="请选择" v-model="c.mediaType">
              <el-option label="图片" value="1"></el-option>
              <el-option label="视频" value="2"></el-option>
              <el-option label="文本" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-show="c.mediaType ==1" label="封面">
            <!--                    上传组件
              name 请求参数的名称
              limit 上传的文件的数量
              on-success 上传成功事件
              accept 是视频上传    action="http://localhost:8081/api/announcement/upload"
            -->
            <el-upload
                v-model:file-list="fileList"
                action="https://www.jingtengyiliao.com/api/announcement/upload"
                name="file"
                limit="1"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
                :before-upload="beforeUpload"
                :headers="headers"
            >
              <el-icon>
                <Plus/>
              </el-icon>
            </el-upload>

            <el-dialog v-model="dialogVisible">
              <img w-full :src="dialogImageUrl" alt="Preview Image"/>
            </el-dialog>
            <!--                上传文件的组件结束-->
          </el-form-item>
          <el-form-item v-show="c.mediaType==2" label="视频">
            <el-upload
                v-model:file-list="fileList"
                action="https://www.jingtengyiliao.com/api/common/uploadVideo"
                name="file"
                limit="1"
                accept="video/*"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
                :headers="headers"
            >
             <el-button type="primary">点击上传视频</el-button>
              <div>只能上传mp4文件，且不超过10MB</div>
            </el-upload>

            <el-dialog v-model="dialogVisible">
              <img w-full :src="dialogImageUrl" alt="Preview Image"/>
            </el-dialog>
          </el-form-item>

          <el-form-item v-show="c.mediaType!=2" label="公告内容">
            <!--                div 的ref是为绑定对应的变量-->
            <div ref="editorContent"></div>

          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="post()">发布内容</el-button>
          </el-form-item>
        </el-form>

      </el-card>
    </el-scrollbar>
    </el-container>
  </el-container>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import {Plus} from '@element-plus/icons-vue'
import {ElMessage} from 'element-plus'
import Editor from 'wangeditor';
import router from "@/router";
import qs from "qs";
import instance from "@/network/storester";


const handleChange = () => {  //函数的方式监听当前的信息
  // 调用方法
  loadCategories();
}

/** 处理二级分类 当前先不用**/
// const categoryArr = ref([]);

/** 添加的表单的数据信息js **/
const c = ref({title: "", status: "", mediaType: ""});
const post = () => {
  if (c.value.title.trim() == '') {
    ElMessage.error('标题不能为空');
    return;
  }
  // if (c.value.mediaUrl == null) {
  //   ElMessage.error('请选择封面');
  //   return;
  // }
  // 得到当前登录的用户信息
  let user = localStorage.user ? JSON.parse(localStorage.user) : null;
  if (user == null) {
    alert("请先登录！");
    router.push('/');
    return;
  }
  console.log(user);
  c.value.author = user.id;  //设置作者id
  console.log(c.value.createBy);
  // 把富文本编辑器里面的内容取出  取出的是包含HTML标签的内容
  c.value.content = editor.txt.html();
  console.log(c.value.content);
  // 设置摘要（正文的前30个字符作为摘要） 得到的是纯文本内容的信息（不包含HTML标签）
  // c.value.brief = editor.txt.text().slice(0,30);
  // console.log(c.value.brief);
  let data = qs.stringify(c.value);
  console.log(data);
  instance.post("/api/announcement/add-new", data)
      .then((response) => {
        if (response.data.state == 20000) {
          ElMessage.success("发布完成");
          router.push('/ad'); // 跳转到公告管理页面下架或者发布
        }
      })
}


/**     上传文件的js代码    **/
const fileList = ref([])

const dialogImageUrl = ref('')
const dialogVisible = ref(false)
const handleSuccess = (response, file, files) => {

  // 此处的response就是服务器响应的对象
  c.value.mediaUrl = response.data;
}

const headers = ref({Authorization: ''});

const handleRemove = (uploadFile, uploadFiles) => {
  // 点击删除的时候将对象的值设置为null
  c.value.mediaUrl = null;
  // 发出删除文件的请求
  let url = uploadFile.response.data;//得到当前的文件路径
  instance.delete("/api/upload/remove?url="+url)
      .then((response) =>{
        if (response.data.state == 20000){
          ElMessage.success("删除文件完成！")
        }
      })


  // console.log(uploadFile, uploadFiles)
}

const handlePictureCardPreview = (uploadFile) => {
  dialogImageUrl.value = uploadFile.url
  dialogVisible.value = true
}


/** 富文本编辑器相关的 **/
const editorContent = ref(null);
let editor = null;
onMounted(() => {
  // 实例化富文本编辑器对象，需要用到页面中的div
  editor = new Editor(editorContent.value);
  // 设置占位文本
  editor.config.placeholder = "请输入公告内容详情...";
  // 将富文本编辑器渲染到页面中
  editor.create();

  // 发请求获取二级分类数据，,需要变化的话从数据库中拿取

  // 设置token
  const token = window.sessionStorage.getItem('token');
  if (token) {
    headers.value.Authorization = token;
  }

})
// 定义一个方法
const loadCategories = () => {
  console.log("单前绑定的信息：" + c.value.type);

}
</script>

<style>


.containersae {
  position: fixed;
  top: 7vh;
  left: 14vw;
}



.main {
  position: fixed;
  top: 10vh;
  left: 15vw;
  width: 85vw;
  height: 90vh;
}
.inputAddnn{
  width: 15vw;
}
.nas{
  width: 20vw !important;
}
</style>